<template>
	<div class="container">
		<div class="top-nav-bar">
			<TopNavSearchBar @searchClick="searchClick" :clear-search-name="clearSearchName" />
			<div class="filter-nav" id="filter-nav">
				<div style="width: 25%;">
					<span :class="{ 'text-blue': showTag }">客户标签</span>
					<van-icon v-if="showTag" name="arrow-up" size="12" color="#36C9FF" style="padding-left: 5px" />
					<van-icon v-else name="arrow-down" size="12" color="#cccccc" style="padding-left: 5px" />
				</div>
				<div style="width: 25%;" @click="checkShowLevel('1')">
					<span :class="{ 'text-blue': showLevel }">授权等级</span>
					<van-icon v-if="showLevel" name="arrow-up" size="12" color="#36C9FF" style="padding-left: 5px" />
					<van-icon v-else name="arrow-down" size="12" color="#cccccc" style="padding-left: 5px" />
				</div>
				<div style=" width: 25%;" @click="filterNumber">
					<span :class="{ 'text-blue': showNumber }">访问次数</span>
					<van-icon v-if="showNumber" name="arrow-up" size="12" color="#36C9FF" style="padding-left: 5px" />
					<van-icon v-else name="arrow-down" size="12" color="#cccccc" style="padding-left: 5px" />
				</div>
				<div style="width: 25%" @click="filterTime">
					<span :class="{ 'text-blue': showTime }">访问时间</span>
					<van-icon v-if="showTime" name="arrow-up" size="12" color="#36C9FF" style="padding-left: 5px" />
					<van-icon v-else name="arrow-down" size="12" color="#cccccc" style="padding-left: 5px" />
				</div>
			</div>
		</div>
		<div class="sroll">
			<van-list v-model="loading" @refresh="onRefresh" :finished="finished" @load="onLoad"
				:immediate-check="immediateCheck" offset="200">
				<div class="pro-content-list">
					<RecommendList v-if="customerList.length !== 0" :list="customerList" @check="checkCustomer" />
					<div v-if="showEmpty" class="product-item">
						<span>暂无数据</span>
					</div>
				</div>
			</van-list>
			<WaterMark style="padding-bottom: 80px" />
		</div>

		<!-- 底部弹出 -->

		<van-popup v-model="showPopup" position="bottom">
			<van-picker show-toolbar :columns="columns" @cancel="showPopup = false" @confirm="onConfirm"
				value-key="name" />
		</van-popup>

		<van-overlay :show="showBang">
			<div class="flex-center" style="height: 100%;">
				<div class="wrapper">
					<div class="flex-center">
						<img src="@/assets/img-v3/new-customer/bang.png" class="bang-img" />
					</div>
					<div class="flex-center bang-text">确定要拉黑吗?</div>
					<div class="flex-btw btn-foot">
						<div class="btn-cancel flex-center" @click="showBang = false">取消</div>
						<div class="btn-confirm flex-center" @click="shield">确定</div>
					</div>
				</div>
			</div>
		</van-overlay>

		<van-overlay :show="showTags">
			<div class="flex-center" style="height: 100%;">
				<div class="wrapper">
					<div class="flex-center" style="padding: 10px;">请输入标签</div>
					<div class="flex-center bang-text">
						<van-field v-model="tag" round border placeholder="请输入标签" />
					</div>
					<div class="flex-btw btn-foot">
						<div class="btn-cancel flex-center" @click="showTags = false">取消</div>
						<div class="btn-confirm flex-center" @click="updateTags">确定</div>
					</div>
				</div>
			</div>
		</van-overlay>

	</div>
</template>
<script>
	import TopNavSearchBar from "./components/TopNavSearchBar.vue";
	import RecommendList from "./components/RecommendList.vue";
	import {
		listCustomer,
		updateUser
	} from "@/api";
	import {
		Popup,
		Picker,
		Toast,
		Dialog
	} from 'vant';

	export default {
		name: "CustomerList",
		components: {
			TopNavSearchBar,
			RecommendList,
		},
		data() {
			return {
				showBang: false,
				showTags: false,
				tag: '',
				columns: [],
				showPopup: false,
				immediateCheck: false,
				customerList: [],
				showList: false,
				immediate: false,
				active: 0,
				clearSearchName: "",
				showTag: false,
				showLevel: false,
				showNumber: false,
				showTime: false,
				sortName: "",
				pageSize: 10,
				page: 0,
				finished: false,
				loading: false,
				total: 0,
				showEmpty: false,
				user: '',
				showLevelType: '1', //1 是筛选  2 是修改
				lv: '', // 1 全部等级 2 外地客户（全部可见） 3 本地客户（全部不可见） 4 所有客户（扫码可见）
				order: 0 // 0 - 时间正序 1 - 时间倒序2 - 次数正序3 - 次数正序
			};
		},
		mounted() {
			// this.onRefresh()
		},
		methods: {
			filterTime() {
				this.showTime = !this.showTime;
				if (this.showTime) {
					this.order = 0
				} else {
					this.order = 1
				}
				this.filterCustomer()

			},
			filterNumber() {
				this.showNumber = !this.showNumber
				if (this.showNumber) {
					this.order = 2
				} else {
					this.order = 3
				}
				this.filterCustomer()
			},
			filterCustomer() {
				this.page = 0
				this.customerList = []
				this.onRefresh()
			},
			/**
			 * 
			 * */
			searchClick(nick) {
				this.page = 0
				this.nick = nick
				this.customerList = []
				this.onRefresh()
			},
			checkShowLevel(type) {
				this.columns = [{
					name: '全部等级',
					id: 1
				}, {
					name: '外地客户（全部可见）',
					id: 2
				}, {
					name: '本地客户（全部不可见）',
					id: 3
				}, {
					name: '所有客户（扫码可见）',
					id: 4
				}]
				this.showPopup = !this.showPopup
				this.showLevelType = type

			},
			/**
			 * 拉黑
			 * */
			shield() {
				this.showBang = false
				// block 是 拉黑  0 是没拉黑  1是拉黑了
				updateUser({
					openid: this.user.openid,
					block: 1
				}).then(res => {
					if (res.state == 100) {
						this.showTags = false
					}
				})
			},
			//修改标签
			updateTags() {
				updateUser({
					openid: this.user.openid,
					tags: this.tag,
					shopUuid: this.$store.state.shopUuid,
				}).then(res => {
					if (res.state == 100) {
						this.user.tags = this.tag
						this.showTags = false
					}
				})
			},
			checkCustomer(type, item) {
				this.user = item;
				if (type === 'bang') { //拉黑
					this.showBang = true
				}
				if (type === 'tags') { //改标签
					this.showTags = true
				}
				if (type === 'level') { //改等级
					this.columns = [{
						name: '外地客户（全部可见）',
						id: 2
					}, {
						name: '本地客户（全部不可见）',
						id: 3
					}, {
						name: '所有客户（扫码可见）',
						id: 4
					}]
					this.showPopup = true
					this.showLevelType = 2
				}
			},
			onConfirm(value, index) {
				if (this.showLevelType == 1) {
					this.lv = (value.id == 1 ? '' : value.id);
					this.onRefresh()
					this.showPopup = false
				} else if (this.showLevelType == 2) {
					updateUser({
						openid: this.user.openid,
						lv: value.id,
						shopUuid: this.$store.state.shopUuid,
					}).then(res => {
						if (res.state == 100) {
							this.user.lv = value.id;
							this.$forceUpdate()
							this.showPopup = false
						}
					})
				}
			},
			onCancel() {
				Toast('取消')
			},
			onRefresh() {
				// 清空列表数据
				this.customerList = [];
				this.page = 0;
				// 重新加载数据
				// 将 loading 设置为 true，表示处于加载状态
				this.onLoad();
			},
			getInfo(data) {
				this.$router.push({
					name: "ProductDetails",
					query: data,
				});
			},
			filterType() {
				this.showFilter = !this.showFilter;
				if (this.showFilter) {
					this.showSortBrowse = false;
					this.showNewProduct = false;
				}
			},

			onLoad() {
				this.showEmpty = false;
				const params = {
					page: this.page,
					size: this.pageSize,
					shopUuid: this.$store.state.shopUuid,
					sortName: this.sortName ? this.sortName : "",
					asc: this.asc ? this.asc : "",
					lv: this.lv,
					nick: this.nick,
					order: this.order
				};

				listCustomer(params).then((res) => {
					if (res.state == 100) {
						if (res.items.records.length > 0) {
							res.items.records.map((resp) => {
								this.customerList.push(resp);
							});
						}
						if (this.customerList.length === 0) {
							this.showEmpty = true;
						}
						this.showFilter = false;
						this.total = res.items.total;
						// 加载状态结束
						this.loading = false;
						// 数据全部加载完成
						if (((this.page + 1) * this.pageSize) > this.total) {
							this.finished = true;
						} else {
							this.page = this.page + 1;
						}

					}
				});
			},
		},
	};
</script>

<style lang="less" scoped>
	.wrapper {
		width: 275px;
		height: 160px;
		background: #FFFFFF;
		border-radius: 10px;

		.btn-foot {
			padding: 28px 38px 13px 38px;

			.btn-cancel {
				width: 90px;
				height: 35px;
				background: #F0F0F0;
				border-radius: 18px;
				font-size: 16px;
				font-family: HarmonyOS Sans SC;
				font-weight: 400;
				color: #646464;
			}

			.btn-confirm {
				width: 90px;
				height: 35px;
				background: #36C9FF;
				border-radius: 18px;
				font-size: 16px;
				font-family: HarmonyOS Sans SC;
				font-weight: 400;
				color: #fff !important;
			}
		}

	}

	.bang-img {
		height: 39px;
		width: 39px;
		margin-top: 21px;
	}

	.bang-text {
		margin-top: 10px;
		font-size: 16px;
		font-family: HarmonyOS Sans SC;
		font-weight: 400;
		color: #000000;
	}

	.container {
		padding-top: 54px;

		.sroll {
			padding-top: 38px;
		}

		//解决底部出现大量空白，以及列表项显示不全的问题
		._v-container>._v-content>.pull-to-refresh-layer {
			width: 100%;
			height: 120px;
			margin-top: -120px;
			text-align: center;
			font-size: 32px;
			color: #aaa;
			float: left;
			top: 0;
			left: 0;
		}

		.text-cut {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		.text-blue {
			color: #3d51ff;
		}

		.filter-nav {
			padding: 0 0 10px 0;
			width: 100%;
			background-color: #ffffff;
			line-height: 20px;
			display: flex;
			justify-content: space-between;
			text-align: center;
			font-size: 15px;
			color: #505050;
			position: relative;
		}

		.type-btn {
			width: 100%;
			background-color: #ffffff;
			padding: 5px;
			display: flex;
			flex-wrap: wrap;
			height: 40%;
			overflow-y: scroll;

			.btn {
				border-radius: 50px;
				line-height: 20px;
				padding: 6px 15px;
				margin: 4px 5px;
				color: #ffffff;
			}
		}

		&::before {
			content: "";
			display: table;
		}

		.top-nav-bar {
			position: fixed;
			top: 0;
			width: 100%;
			height: 60px;
			z-index: 999;

			.panel {
				height: 100%;
				width: 100%;
				background-color: rgba(0, 0, 0, 0.6);
				position: fixed;
				top: 88px;
			}
		}

		.pro-type {
			width: 100vw;
			overflow: hidden;
			position: fixed;
			top: 60px;
			z-index: 999;
		}

		.pro-content-list {
			width: 100vw;
			// height: 100%;
			// overflow-y: scroll;
			padding-bottom: 40px;

			.product-item {
				width: 361px;
				height: 150px;
				background: #ffffff;
				box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.03);
				border-radius: 10px;
				padding: 10px;
				display: flex;
				justify-content: center;
				align-items: center;
				margin: 10px auto;


				&>span {
					font-size: 24px;
					font-family: PingFang SC;
					font-weight: 700;
					color: #969696;
				}
			}
		}

		.content {
			.pro-content-list {
				padding-bottom: 30px;
			}
		}
	}
</style>
