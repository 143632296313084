<template>
	<van-nav-bar left-arrow>
		<template #left>
			<img width="28px" height="28px" src="~@/assets/img/order/back-left.png" alt="" @click="goBack(1)">
		</template>
		<template #title>
			<div class="search-top">
				<img class="left" src="@/assets/img-v3/new-home/searchIcon.png" @click="searchClick">
				<input v-model="searchName" placeholder="搜索您想找的用户" class="search-input" @keyup.enter="searchClick">
				<!-- <img @click="handleVoice" class="right" src="@/assets/img/home/voice.png" /> -->
			</div>
		</template>
	</van-nav-bar>
</template>

<script>
	import {
		Toast
	} from 'vant'
	export default {
		name: 'TopNavBar',
		props: {
			clearSearchName: {
				type: String,
				default: ''
			}
		},
		data() {
			return {
				searchName: ''
			}
		},
		watch: {
			clearSearchName(newValue, oldValue) {
				if (newValue) {
					this.searchName = ''
				}
			}
		},
		methods: {
			goBack(type) {
				switch (type) {
					case 1:
						this.$router.go(-1)
						break
					case 2:
						this.$router.push({
							name: 'Home',
							query: {
								shopUuid: this.$store.state.shopUuid
							}
						})
						break
				}
			},
			searchClick(e) {
				this.$emit('searchClick', this.searchName)
			},
			handleVoice() {
				Toast('网页暂不支持')
			}
		}
	}
</script>

<style lang="less" scoped>
	/deep/ .van-nav-bar__title{
		max-width: 100% !important;
	}
	.search-top {
		width: 250px;
		height: 33px;
		background: #F0F0F0;
		border-radius: 16px;
		display: flex;
		align-items: center;
		position: relative;
		.left {
			margin: 0 8px 0 13px;
		}

		.right {
			position: absolute;
			right: 12px;
		}

		&>.search-input {
			width: 250px;
			height: 30px;
			
			border-radius: 15px;
			line-height: 30px;
			white-space: nowrap;
			font-size: 13px;
			font-family: PingFang SC;
			font-weight: 400;
			color: #646464;
			border: none;
			outline: none;
			background: transparent;
			text-align: center;

			&::placeholder {
				color: #646464;
			}
		}

		&>img {
			width: 17px;
			height: 17px;
		}
	}

	.home-logo {
		height: 30px;
		margin-top: 8px;
	}

	.home-left,
	.home-right {
		width: 29px;
		height: 29px;
	}

	[class*="van-hairline"]::after {
		border: none;
	}
</style>
