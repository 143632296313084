<template>
	<div class="recommend-container flex-center">
		<div class="customer-list" v-if="list.length > 0">
			<div v-for="(item,index) in list" :key="index" class="customer">
				<div class="flex-btw">
					<div class="flex">
						<img class="header" :src="item.headimgurl" referrerpolicy="no-referrer"/>
						<div class="flex flex-center">
							<div class="padding-left-sm text-cut">
								<div class="padding-bottom">{{item.nickname}}</div>
								<div class="customer-tag text-cut">
									{{item.lv ==2 ? '外地客户(全部可见)':item.lv ==3?'本地客户（全部不可见）':item.lv ==4?'所有客户（扫码可见）':''}}
								</div>
							</div>
						</div>
					</div>
					<div>
						<div class="call" @click="call(item)">
							打电话
						</div>
					</div>
				</div>
				<div class="padding-top text-gray text-cut">
					账号ID：{{item.openid}}
				</div>
				<!-- <div class="padding-top text-gray text-cut">
					上级业务员： <span style="color: #FF6630 !important;">管理员</span>
				</div> -->
				<div class="padding-top text-gray text-cut">
					标签：{{item.tags}}
				</div>
				<div class="flex-btw padding-top text-gray ">
					<div class="text-blue text-sm ">最新到访: {{ item.newTime?toTime(item.newTime):'--'}} </div>
					<div class="text-sm">累计访问 <span style="font-size: 18px;color: #FF1313 !important;">{{item.visitTimes}}</span> 次</div>
				</div>
				<div class="padding-top-sm text-cut" >
					当天第 <a class="text-blue">{{item.visitProductTimes}}</a> 次浏览产品 【{{item.productName}}】
				</div>
				<div class="flex-btw padding-top">
					<div class="border flex-center">
						改上级
					</div>
					<div class="border flex-center" @click="check('tags',item)">
						改标签
					</div>
					<div class="border flex-center" @click="check('level',item)">
						改等级
					</div>
					<div class="border flex-center" @click="check('bang',item)">
						拉黑
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		timestampToTime
	} from '@/utils/index.js'
	import {
		Toast
	} from 'vant'
	export default {
		name: 'RecommendList',
		components: {},
		props: {
			page: {
				type: Number,
				default: 1
			},
			list: {
				type: Array,
				default: []
			}
		},
		data() {
			return {}
		},

		methods: {
			call(item) {
				if (item.phone) {
					window.location.href = 'tel://' + item.phone
				} else {
					Toast('请完善电话号码')
				}

			},
			toTime(time) {
				return timestampToTime(time)
			},
			getInfo(data) {
				this.$emit('getInfos', data)
			},
			check(type, item) {
				this.$emit('check', type, item)
			}
		}
	}
</script>

<style lang="less" scoped>
	.recommend-container {
		.customer-list {
			.customer {
				width: 350px;
				background: #FFFFFF;
				box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
				border-radius: 10px;
				padding: 14px;
				margin-top: 15px;

				.padding-bottom {
					padding-bottom: 6px;
				}

				.header {
					width: 55px;
					height: 55px;
					background: #AEAEAE;
					border-radius: 50%;
				}

				.customer-tag {
					line-height: 20px;
					background: #FFAF30;
					border-radius: 5px;
					color: #ffffff;
					padding: 0 5px;
				}

				.call {
					line-height: 20px;
					background: #2AE3BA;
					border-radius: 5px;
					color: #ffffff;
					padding: 0 5px;
				}

				.border {
					width: 70px;
					height: 30px;
					border: 1px solid #DCDCDC;
					border-radius: 15px;
					font-size: 13px;
					font-family: HarmonyOS Sans SC;
					font-weight: 400;
					color: #000000;
				}
			}
		}

		.title {
			background: #ffffff;
			width: 100%;
			height: 47px;
			display: flex;
			align-items: center;
			justify-content: flex-start;

			&>img {
				width: 20px;
				height: 20px;
				margin: 0 8px 0 28px;
			}

			&>span {
				font-size: 18px;
				font-family: PingFang SC;
				font-weight: bold;
				color: #323232;
				line-height: 10px;
			}
		}

		.loading {
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
</style>
